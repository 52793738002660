import { Env } from '@app/env';

export const environment: Env = {
  production: true,
  name: 'prod',
  region: 'us-west-1',
  version: '63.16.0',

  defaultOffsetFromUtc: 8,
  fixedDateMs: '2022-05-26T02:00:00Z',

  shortDomain: 'bscr.io',
  downloadableBucket: 'brightscrip.com-downloadables',
  uploadableBucket: 'brightscrip.com-uploads',
  patientsBucket: 'brightscrip-patients-prod',

  captchaApiKey:
    'qO+fokBA5Oe5M5sXhC3V2lpYKEiESD8dZV9hY6jRR39b7bAgpJ8Lt4hC758xTMXIgTkOjyC4z1s4wOOnParPBvQE23Zs5MitBKeISHIr/dhQaTY8t1tr2hUSbirmCIlrIz2ek7xZXYd7GTgmNxNyl3FMwG7G2BUjbZQuoICy3ORk8nQQZludMzH0Ro8XVYSfDL/rEPc5LRO3OFUf2U4+Fsyjjs3FUzJPGJsLw8q+yea4N3V+Bv3pHiHW7LNLZve6rY4pGtMfYaHs6EuMI//OPaUGUUpdjqlQhHiYUWKqemnSXDw7G/yimSluRBrssPzcGppp0CAdT9Or5IxINDERfpT8Is2dUlIHF3i+7DCc7GWwSQEEybEh8t7p7iperkFGcw93PIM6PCItzZCLzjegwVpY9zbSoVdW5a4FM4w5iA3TlO+3JeNbAct3gvFXAJwgt2GJGyZ19V0WqFaDrlUcsrmyffW4UnHI3n/buO+uBDVra+JZJSlrdvkYy9c+oILD+NGFFLEQmYYuLoC5iOcUZU2R1cyh57reaD0jb660+fYhIsXod/kTBIAdyIZfxy2/c2OYUYXwAbam0MywLWnqQG1eVHadhM8a9Hz2yk5YAwzQ3T7jHjDenNENzjV3SG6+J5yXcZn0TUVBOz5Iu9Ae1Kx52/URQj1xA5XK1fbb4/k=_0_1',
  captchaUrl: 'https://4afb4d831fff.us-east-1.captcha-sdk.awswaf.com/4afb4d831fff/jsapi.js',
  captchaEnabled: true,
  mixpanelProjectToken: '996d0a70f7d31be5643629772bf76594',
  growthbook: {
    clientKey: 'sdk-1jPWGg66XatSordf',
    enabledDevMode: false,
  },
  posthog: {
    token: 'phc_pD0bH5FahBXTalFS8NtlkCu23zbPr2tjVd6dkPmw2VJ',
    host: 'https://app.posthog.com',
  },
  sentry: {
    enabled: true,
    dsn: 'https://6ff654c859384ce4969c5266fab18827@sentry.io/1509087',
  },

  cognito: {
    userpoolId: 'us-west-1_moa9846uO',
    appClientId: '58o4rmd52bj26cp15kbvue1vgc',
  },

  graphql: {
    endpoint: 'https://nulu5luwebci7idif7bjcyqxpu.appsync-api.us-west-1.amazonaws.com/graphql',
    region: 'us-west-1',
  },

  openSearchUrl: 'https://ih1hwcfh1h.execute-api.us-west-1.amazonaws.com/prod/proxy',

  codeMainUrl: 'https://f26pb60yth.execute-api.us-west-1.amazonaws.com/prod',
  codeRequestUrl: 'https://wrj44dvqgl.execute-api.us-west-1.amazonaws.com/prod',
  codeRequestAggregatesUrl: 'https://wr6h47ofth.execute-api.us-west-1.amazonaws.com/prod',
  dataDeliveryUrl: 'https://hanwtxg1i4.execute-api.us-west-1.amazonaws.com/prod',
  devOpsUrl: 'https://q4zl80a44d.execute-api.us-west-1.amazonaws.com/prod',
  documentsUrl: 'https://np5jewnt54.execute-api.us-west-1.amazonaws.com/prod',
  etlUrl: 'https://g77pre7pu6.execute-api.us-west-1.amazonaws.com/prod',
  faxUrl: 'https://4lnfpq6d66.execute-api.us-east-1.amazonaws.com/prod',
  identityUrl: 'https://vg176gb7kg.execute-api.us-west-1.amazonaws.com/prod',
  inboundsCallsUrl: 'https://ll3whob216.execute-api.us-west-1.amazonaws.com/prod',
  notificationUrl: 'https://vnkjipkq29.execute-api.us-west-1.amazonaws.com/prod',
  pharmacyUrl: 'https://4jou9xothg.execute-api.us-west-1.amazonaws.com/prod',
  prescriberUrl: 'https://739zq2jnbb.execute-api.us-west-1.amazonaws.com/prod',
  rxConnectUrl: 'https://a4rlipm5i0.execute-api.us-west-1.amazonaws.com/prod',
  sftpUrl: 'https://ik08j3ke18.execute-api.us-east-1.amazonaws.com/prod',
  surveillanceUrl: 'https://fd9fdt0qxd.execute-api.us-west-1.amazonaws.com/prod',
  supportUrl: 'https://2zbm0bt0i3.execute-api.us-west-1.amazonaws.com/prod',

  aggregatesUrl: 'https://deqhfwuvtk.execute-api.us-west-1.amazonaws.com/prod',
  copayAssistanceServiceUrl: 'https://v7z7dklw9b.execute-api.us-west-1.amazonaws.com/prod',
  copayUrl: 'https://v7z7dklw9b.execute-api.us-west-1.amazonaws.com/prod',
  masterDataUrl: 'https://e7zrvggehk.execute-api.us-west-1.amazonaws.com/prod',
  paUrl: 'https://r68bwhmw40.execute-api.us-west-1.amazonaws.com/prod',
  rxTriageServiceUrl: 'https://08vv7vxz1i.execute-api.us-west-1.amazonaws.com/prod/',
  tableEditorServiceUrl: 'https://vww3z7sgvb.execute-api.us-west-1.amazonaws.com/prod/',
  tokenActionUrl: 'https://sre9kv88m4.execute-api.us-east-1.amazonaws.com/prod',
  triageDeltaServiceUrl: 'https://hq5vqgrf5l.execute-api.us-west-1.amazonaws.com/prod/',
};
